import { push } from 'connected-react-router'
import { call, put, fork } from 'redux-saga/effects'
import * as types from '../actionTypes';
import * as api from '../services/api';
import { error, success } from './alerts';
import { deleteAll } from '../services/fs';

export function* login(action) {
  yield put( { type: types.LOGIN_STARTED });

  try {
      // Get token
      const loginResponse = yield call(api.login, action.credentials);
      localStorage.setItem('token', loginResponse.data.access_token);

      // Get me
      const meResponse = yield call(api.me);
      localStorage.setItem('user', meResponse.data);

      yield put( { type: types.LOGIN_SUCCESSFUL, user: meResponse.data } );

      yield fork(success, 'Anmeldung', 'Sie wurden erfolgreich angemeldet.', 3);

      yield put(push('/'));
    } catch (e) {
      console.error(e.response);
      yield put( { type: types.LOGIN_FAILED });
      yield fork(error, 'Fehler', 'Bitte überprüfen Sie Ihre Anmeldedaten.', 5);
      return;
    }
}

export function* logout() {
  yield fork(deleteAll);
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  yield put(push('/anmelden'))
  yield call(success, 'Abmeldung', 'Sie wurden erfolgreich abgemeldet.', 3);
}
