export const removeObjectField = (obj, field) => {
    const { [field]: remove, ...rest } = obj;
    return rest;
}


export const optimizeWorkImage = (dataString, callback) => {
  var img = new Image();
  img.onload = resizeImage;
  img.src = dataString;

  function resizeImage() {
    // Scale down the images to a width of 300px
    var newDataUri = imageToDataUri(this, 300);
    callback(newDataUri);
  }
}

const imageToDataUri = (img, width) => {

    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = width * img.height / img.width;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, width * img.height / img.width);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
}
