import {SET_WORKS, SET_WORK_EDIT, SET_MY_WORKS, FETCH_WORK, default as actionTypes} from '../actionTypes'

export function setWorks(works) {
  return {
    type: SET_WORKS,
    works
  }
}

export function setMyWorks(works, attach) {
  return {
    type: SET_MY_WORKS,
    works,
    attach
  }
}

export function setWorkToEdit(work) {
  return {
    type: SET_WORK_EDIT,
    work
  }
}

export function editWork(workId) {
  return {
    type: FETCH_WORK,
    workId: workId }
}
