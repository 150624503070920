import * as types from "../actionTypes";
import { removeObjectField } from "../services/helpers";

const initialState = {
  uploading: false,
  works: {},
  editedWorks: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    //creation of new work
    case types.ADD_WORK: {
      return {
        ...state,
        works: {
          ...state.works,
          [action.tempId]: action.payload
        }
      }
    }
    case types.WORK_UPLOAD_STARTED: {
      return {
        ...state,
        uploading: true,
        works: {
          ...state.works,
          [action.tempId]: {
            ...state.works[action.tempId],
            uploading: true,
            uploaded_at: new Date(),
          }
        }
      }
    }
    case types.WORK_UPLOAD_FAILED: {
      return {
        ...state,
        uploading: false,
        works: {
          ...state.works,
          [action.tempId]: {
            ...state.works[action.tempId],
            uploading: false,
          }
        }
      }
    }
    case types.WORK_UPLOAD_SUCCESSFUL: {
      return {
        ...state,
        uploading: false,
        works: removeObjectField(state.works, action.tempId),
      }
    }

    //updating work
    case types.ADD_EDITED_WORK: {
      let editedWorks = state.editedWorks;
      Object.keys(editedWorks).forEach(function (savedWorkTemId) {
        if (editedWorks[savedWorkTemId].id === action.payload.id) {
          delete editedWorks[savedWorkTemId];
        }
      });

      return {
        ...state,
        editedWorks: {
          ...editedWorks,
          [action.tempId]: action.payload
        }
      }
    }
    case types.EDITED_WORK_UPLOAD_STARTED: {
      return {
        ...state,
        uploading: true,
        editedWorks: {
          ...state.editedWorks,
          [action.tempId]: {
            ...state.editedWorks[action.tempId],
            uploading: true,
            uploaded_at: new Date(),
          }
        }
      }
    }
    case types.EDITED_WORK_UPLOAD_FAILED: {
      return {
        ...state,
        uploading: false,
        editedWorks: {
          ...state.editedWorks,
          [action.tempId]: {
            ...state.editedWorks[action.tempId],
            uploading: false,
          }
        }
      }
    }
    case types.EDITED_WORK_UPLOAD_SUCCESSFUL: {
      return {
        ...state,
        uploading: false,
        editedWorks: removeObjectField(state.editedWorks, action.tempId),
      }
    }

    case types.SET_PENDING_UPDATE_CREATED: {
      return {
        ...state,
        uploading: false,
        works: {
          ...state.works,
          [action.payload.tempId]: {
            ...action.payload,
            uploading: false,
          },
        }
      }
    }

    case types.LOGOUT: {
      return initialState
    }

    default:
      return state;
  }
}
