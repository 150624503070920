import React from 'react';
import { Message } from 'semantic-ui-react'
import WorkWidget from '../WorkWidget'
import Loader from '../Loader'

export default ({loading, works, viewWorksAll, deleteWork, editWork, markAsSolved, pendingEditedWorks, user}) => {

  if( loading && works.length === 0) {
    return <Loader />;
  }
  if (!viewWorksAll) {
    works = works.filter((work) => {
      return work.user_id === user.id;
    });
  }
  // Projects found?
  if( works && works.length > 0) {
    return (
      <div>
        {works.map(work => <WorkWidget
          key={'works-list-'+work.id}
          work={work}
          deleteWork={ deleteWork }
          editWork={ editWork }
          markAsSolved={ markAsSolved }
          pendingEditedWorks={ pendingEditedWorks }
          user={user}
        /> )}
      </div>
    );
  }

  return (
    <Message
      icon='times'
      header='Keine Arbeiten vorhanden'
    />
  )
};
