export const store = (fileName, content) => {
  return new Promise( (resolve, reject) => {
    const onInitFs = fs => {

      fs.root.getFile(fileName, {create: true}, function(fileEntry) {

        // Create a FileWriter object for our FileEntry (log.txt).
        fileEntry.createWriter(function(fileWriter) {

          fileWriter.onwriteend = function(e) {
            console.log('Write completed.');
            resolve();
          };

          fileWriter.onerror = function(e) {
            console.log('Write failed: ' + e.toString());
            reject();
          };

          // Create a new Blob and write it to log.txt.
          var blob = new Blob([content], {type: 'text/plain'});
          fileWriter.write(blob);

        }, error => console.error('FS-Error:' + error));

      }, error => console.error('FS-Error:' + error));
    };

    requestQuota(onInitFs);
  });
}

export const read = (fileName) => {
  return new Promise( (resolve, reject) => {
    const onInitFs = (fs) => {

      fs.root.getFile(fileName, {}, function(fileEntry) {

        // Get a File object representing the file,
        // then use FileReader to read its contents.
        fileEntry.file(function(file) {
           var reader = new FileReader();

           reader.onloadend = function(e) {
             resolve(this.result);
           };

           reader.readAsText(file);
        }, error => resolve(null));

      }, error => resolve(null));

    }

    requestQuota(onInitFs);
  });
}

export const files = () => {
  return new Promise( (resolve, reject) => {

    function toArray(list) {
      return Array.prototype.slice.call(list || [], 0);
    }

    const onInitFs = (fs) => {

      var dirReader = fs.root.createReader();
      var entries = [];

      // Call the reader.readEntries() until no more results are returned.
      var readEntries = function() {
         dirReader.readEntries (function(results) {
          if (!results.length) {
            resolve(entries.map( entry => entry.name ));
          } else {
            entries = entries.concat(toArray(results));
            readEntries();
          }
        }, error => console.error('FS-Error:' + error));
      };

      readEntries(); // Start reading dirs.

    }

    requestQuota(onInitFs);
  });
}

export const deleteFile = fileName => {
  return new Promise( (resolve, reject) => {

    const onInitFs  = fs => {
      fs.root.getFile(fileName, {create: false}, function(fileEntry) {

        fileEntry.remove(function() {
          console.log('File removed.');
          resolve(true);
        }, error => resolve(false));

      }, error => resolve(false));
    }

    requestQuota(onInitFs);
  });
}

export const deleteAll = () => {
  return new Promise( (resolve, reject) => {
    files().then( files => {
      for (var file of files) {
        deleteFile(file);
      }
      resolve();
    });
  });
}

export const requestQuota = onInitFs => {
  var requestedBytes = 1024*1024*500;
  navigator.webkitPersistentStorage.requestQuota (
      requestedBytes, function(grantedBytes) {

        window.requestFileSystem  = window.requestFileSystem || window.webkitRequestFileSystem;

        window.requestFileSystem(1, grantedBytes, onInitFs, error => console.error(error));

      }, function(e) { console.log('Error', e); }
  );
}
