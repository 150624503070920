import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from "../../actionTypes";

class ErrorAlerts extends Component {
  componentDidCatch(error, info) {
    this.props.dispatchError(error, info);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchError: (title, message) => dispatch({ type: actionTypes.ERROR_ALERT, payload: { title, message }}),
  }
};

export default connect(null, mapDispatchToProps)(ErrorAlerts);
