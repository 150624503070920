import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import projects from "./projects";
import pendingWorks from "./pendingWorks";
import works from "./works";
import user from "./user";
import alerts from "./alerts";
import online from "./online";
import myWorks from "./myWorks";

export default (history) => combineReducers({
  router: connectRouter(history),
  projects,
  pendingWorks,
  works,
  user,
  alerts,
  online,
  myWorks
})
