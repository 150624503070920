import * as types from "../actionTypes";

const initialState = {
  loading: false,
  allIds: [],
  byIds: {},
  offlinePlanIds: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_PROJECTS: {
      const { projects } = action;

      let allIds = [];
      let byIds = {};

      projects.forEach(project => {
        allIds.push(project.id);
        byIds[project.id] = project;
      });

      return {
        ...state,
        allIds: allIds,
        byIds: byIds,
      };
    }

    case types.UPDATE_OFFLINE_WORKS_FOR_PLAN: {
      const projectId = action.payload.plan.project_id;
      const planId = action.payload.plan.id;
      let byIds = state.byIds;

      let planIndex = byIds[projectId].plans.findIndex((plan) => {
        return plan.id === planId;
      });

      byIds[projectId].plans[planIndex] = {...action.payload.plan};
      let offlinePlanIds;

      if (action.payload.deletePlan) {
        offlinePlanIds = state.offlinePlanIds.filter(id => id !== planId)
      } else {
        offlinePlanIds = [...state.offlinePlanIds || [], planId]
      }
      return {
        ...state,
        byIds: byIds,
        offlinePlanIds: offlinePlanIds
      };
    }

    case types.FETCH_PROJECTS_STARTED: {
      return {
        ...state,
        loading: true,
      }
    }
    case types.FETCH_PROJECTS_SUCCESSFUL: {
      return {
        ...state,
        loading: false,
      }
    }
    case types.FETCH_PROJECTS_FAILED: {
      return {
        ...state,
        loading: false,
      }
    }
    case types.LOGOUT: {
      return initialState
    }

    default:
      return state;
  }
}
