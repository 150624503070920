import { select, call } from 'redux-saga/effects'
import { files, deleteFile } from '../services/fs';

export function* cleanLocalStorage() {
  // Get pending works
  const pendingWorks = yield select( state => state.pendingWorks.works );

  // Get files
  const existingFiles = yield call( files );

  // Iterate through local storage and fine images.
  for (var fileName of existingFiles) {
    if (fileName.match(/^images./)) {
      let found = false;

      // Check if there is a pending work with this image id
      for (var work in pendingWorks) {
        if (pendingWorks.hasOwnProperty(work) && work.imageId && work.imageId === fileName) {
          found = true;
          break;
        }
      }

      if(!found) yield call(deleteFile, fileName);
    }
  }
}
