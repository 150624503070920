import * as types from '../actionTypes';

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ALERT:
      return Object.assign({}, state, {
        items: state.items.concat({
          id: action.id,
          title: action.title,
          message: action.message,
          alertType: action.alertType,
        })
      })

    case types.REMOVE_ALERT:
    return Object.assign({}, state, {
      items: [...state.items.filter(item => item.id !== action.id)],
    });

    default:
      return state
  }
}
