import React from 'react';
import { Button, Form, Segment, Radio, Icon, Input, Select, Divider } from 'semantic-ui-react'
import FileBase64 from 'react-file-base64';
import SimpleReactValidator from 'simple-react-validator';
import { optimizeWorkImage } from '../../../services/helpers';
import { store } from '../../../services/fs';
import * as actionsModifications from "../../../actionModifications";
import { getWorkTypeFields } from "../../../services/workTypes";
import { history } from "../../../store";
import {connect} from "react-redux";


const calculationOptions = [
  { key: '1', value: 'work', text: 'Ausmass' },
  { key: '2', value: 'management', text: 'Regie' }
];

const targetOptions = [
  { key: '1', value: 'invoice', text: 'Rechnung' },
  { key: '2', value: 'offer', text: 'Angebot' }
];

class EditInfo extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }

  constructor(props) {
    super(props);

    if (props.editType) {

      let workEdit = null;
      const paramWorkId = parseInt(this.props.computedMatch.params.workId);
      let updateType = actionsModifications.DEFAULT;

      if (paramWorkId in this.props.worksByIds) {
        //if work already created and saved to database
        workEdit = this.props.worksByIds[paramWorkId];
      } else if (this.props.myWorks.filter(work => work.id === paramWorkId).length > 0) {
        workEdit = this.props.myWorks.find(work => work.id === paramWorkId);
      } else {
        //work wait for synchronization, created in redux and store but not saved to database
        workEdit = this.props.pendingWorks[paramWorkId];
        updateType = actionsModifications.UPDATE_PENDING_CREATED
      }
      if (this.props.user.id !== workEdit.user_id) {
        history.push('/arbeiten')
      }
      this.state = { ...workEdit, updateType, tempId: paramWorkId };
    } else {
      this.state = this.getInitState();
    }
    this.validator = new SimpleReactValidator();
  }

  render() {
    return (
      <Form>
        <div className="step-headline"><strong>Schritt 3:</strong> Informationen angeben...</div>

        <Segment>
          <h3>{this.props.editType ? this.state.type : this.props.workType.name}</h3>
          {this.getFields()}
          <Divider />
          {this.generalFields()}

          <Button style={{marginTop: 30}} disabled={!this.props.editType && !this.props.canAddMore} icon fluid primary labelPosition='right'
                  onClick={this.handleSubmit.bind(this, false)}>
            <Icon name='check'/>
            {this.props.editType ? 'Aktualisieren' : 'Speichern'}
          </Button>

          {!this.props.editType ?
            <Button style={{marginTop: 10}} disabled={!this.props.canAddMore} icon fluid labelPosition='right'
                  color='grey' onClick={this.handleSubmit.bind(this, true)}>
            <Icon name='undo'/>
            Speichern & Neu
          </Button> : ''}
        </Segment>
      </Form>
    )
  }

  handleSubmit(addAnother = false) {
    if (this.validator.allValid()) {
      if (this.props.editType) {
        this.props.update(this.state);
      } else {
        this.props.submit(this.state, addAnother);
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }

  getInitState() {
    let initState = {
      note: '',
      wisa: false,
      additional_position: false,
      non_confirm_installation: false,
      calculation: calculationOptions[0].value,
      target: this.props.defaultTarget,
    };

    this.props.workType.fields.forEach(field => {
      initState[field.name] = field.hasOwnProperty('defaultValueKey') ?
        this.props.project[field.defaultValueKey] : '';
    });

    return initState;
  }

  getFields() {
    let fields = [];
    let baseFields = null;
    if (this.props.editType) {
      baseFields = getWorkTypeFields(this.state.type)
    } else {
      baseFields = this.props.workType.fields;
    }
    baseFields.forEach(field => {
      switch (field.type) {
        case 'select':
          fields.push( (
            <Form.Field key={'form-field-'+field.name} required>
              <label>{field.label}</label>
              <Select
                fluid
                options={field.options}
                placeholder={field.label}
                value={this.state[field.name]}
                onChange={ (e, data) => this.setState({ [field.name]: data.value }) }
              />
              { field.rules ? this.validator.message(field.name, this.state[field.name], field.rules) : null}
            </Form.Field>
          ) );
          break;

        case 'number':
          fields.push( (
            <Form.Field key={'form-field-'+field.name} required>
              <label>{field.label}</label>
              <Input
                fluid
                value={this.state[field.name]}
                onChange={ event => this.setState({ [field.name]: event.target.value })}
                type='number'
                label={field.unit}
                labelPosition='right'
                placeholder={field.label}
              />
              { field.rules ? this.validator.message(field.name, this.state[field.name], field.rules) : null}
            </Form.Field>
          ) );
          break;

        case 'text':
          fields.push( (
            <Form.Field key={'form-field-'+field.name} required>
              <label>{field.label}</label>
              <Input
                fluid
                value={this.state[field.name]}
                onChange={ event => this.setState({ [field.name]: event.target.value })}
                type='text'
                placeholder={field.label}
              />
              { field.rules ? this.validator.message(field.name, this.state[field.name], field.rules) : null}
            </Form.Field>
          ) );
          break;

        case 'boolean':
          fields.push( (
            <Form.Field key={'form-field-'+field.name} required>
              <Radio label={field.label} toggle
                     checked={ !!this.state[field.name] }
                     onChange={(e, data) => this.handleBooleanChange(e, data, field)}
              />
              { field.rules ? this.validator.message(field.name, this.state[field.name], field.rules) : null}
            </Form.Field>
          ) );
          break;

        default:
          break;
      }
    });

    return fields;
  }

  handleBooleanChange(e, data, field) {
    this.setState({[field.name]: data.checked})
    if (field.uncheck) {
      this.setState({[field.uncheck]: !data.checked})
    }
  }

  onFileChanged(file) {
    optimizeWorkImage(
      file.base64,
      newDataUri => {
        // Generate a random identifier for the file
        const id = 'images.' + Math.random().toString(36).slice(-5);

        // Store the file to the localStorage
        store(id, newDataUri).then( () => { this.setState({imageId: id }) });
      }
    );
  }

  generalFields() {
    return (
      <div>
        <Form.Field label='Notiz' control='textarea' rows='3'
                    value={this.state.note ? this.state.note : ''}
                    onChange={(e) => this.setState({note: e.target.value})}
        />
        <FileBase64
          multiple={ false }
          onDone={ this.onFileChanged.bind(this) } />

        <Divider />

        <Form.Field key={'form-field-calculation'} required>
          <label>Kalkulation</label>
          <Select
            fluid
            options={calculationOptions}
            value={this.state.calculation}
            onChange={ (e, data) => this.setState({ calculation: data.value }) }
          />
          { this.validator.message('calculation', this.state.calculation, 'required')}
        </Form.Field>

        <Divider />

        <Form.Field key={'form-field-target'} required>
          <label>Ziel</label>
          <Select
            fluid
            options={targetOptions}
            value={this.state.target}
            onChange={ (e, data) => this.setState({ target: data.value }) }
          />
          { this.validator.message('target', this.state.target, 'required')}
        </Form.Field>

        <Divider />

        <Radio label='WISA' toggle
               className="radio-field"
               checked={this.state.wisa ? this.state.wisa : false}
               onChange={(e, data) => this.setState({wisa: data.checked})}
        />
        <Radio label='Zusätzliche Position' toggle
               className="radio-field"
               checked={this.state.additional_position ? this.state.additional_position : false}
               onChange={(e, data) => this.setState({additional_position: data.checked})}
        />
        <Radio label='Konformer Einbau nicht möglich' toggle
               className="radio-field"
               checked={this.state.non_confirm_installation ? this.state.non_confirm_installation : false}
               onChange={(e, data) => this.setState({non_confirm_installation: data.checked})}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const project = state.projects.byIds[ownProps.computedMatch.params.projectId];

  return {
    project: project,
  }
};

export default connect(mapStateToProps)(EditInfo)
