import React from 'react';
import { Link } from "react-router-dom";
import { Menu } from 'semantic-ui-react'
import { withRouter } from "react-router";
import './styles.css';

const menuEntries = [
  {
    title: 'Projekte',
    slug: '/',
    matching: [
      RegExp('^/$'),
      RegExp('^/projekt/[0-9]+$'),
      RegExp('^/projekt/[0-9]+/plan/[0-9]+$'),
      RegExp('^/projekt/[0-9]+/plan/[0-9]+/(info|position|)$'),
    ],
    icon: 'home',
  },
  {
    title: 'Meine Arbeiten',
    slug: '/meine-arbeiten',
    matching: [
      RegExp('^/meine-arbeiten$'),
    ],
    icon: 'list',
  },
  {
    title: 'Benutzer',
    slug: '/benutzer',
    matching: [
      RegExp('^/benutzer$'),
    ],
    icon: 'user',
  },
]

class Nav extends React.Component {
  render() {
    return (
      <div className="main-menu">
        <div className="main-menu-container">
          <Menu >
            <Menu.Item>
              <img alt="Logo" src={ require('../../assets/logo.png')} />
            </Menu.Item>

            { menuEntries.map( entry => (
              <Menu.Item active={this.isActive(entry.matching, this.props.location.pathname)} as={Link} to={entry.slug} key={`menu-item-${entry.slug}`}>
                {entry.title}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    )
  }

  isActive(matching, path) {
    for (var regex of matching) {
      if( regex.test(path) ) return true;
    }
    return false;
  }
}

export default withRouter(Nav);
