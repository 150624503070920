import React, { Component } from 'react';
import { Message } from 'semantic-ui-react'
import { connect } from 'react-redux';
import * as actionTypes from '../../actionTypes';

class Alerts extends Component {

  render() {
    if( this.props.alerts.items.lenght === 0) return null;

    return (
      <div>
        {this.props.alerts.items.map(alert => (
          <Message
            key={alert.id}
            negative={alert.alertType === 'error'}
            success={alert.alertType === 'success'}
            info={alert.alertType === 'info'}
            onClick={ () => this.props.removeAlert(alert.id) }
          >
            <Message.Header>{alert.title}</Message.Header>
            <p>{alert.message}</p>
          </Message>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts,
});

const mapDispatchToProps = dispatch => {
  return {
    removeAlert: id => dispatch({type: actionTypes.REMOVE_ALERT, id: id}),
  }
}

// export default TodoList;
export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
