import * as types from "../actionTypes";
import * as api from "../services/api";
import {setMyWorks} from "../actions/works";
import {exception} from "./alerts";
import { call, put, fork } from 'redux-saga/effects'

export function* fetchMyWorks(action) {
  yield put( { type: types.FETCH_MY_WORKS_STARTED });

  try {
    const response = yield call(api.fetchMyWorks, action.length);
    const attach = !!action.length;
    yield put(setMyWorks(response.data.data, attach));
    yield put( { type: types.FETCH_MY_WORKS_SUCCESSFUL } );
  } catch (e) {
    yield fork(exception, e);
    console.error(e.response);
    yield put( { type: types.FETCH_MY_WORKS_FAILED });
    return;
  }
}
