import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import PrivateRoute from './components/PrivateRoute'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Projects from './containers/Projects'
import Project from './containers/Project'
import Plan from './containers/Plan'
import Works from './containers/Works'
import Login from './containers/Login'
import Account from './containers/Account'
import Alerts from './containers/Alerts'
import Offline from './containers/Offline'
import ErrorAlerts from './containers/ErrorAlerts'

import { requestQuota } from './services/fs'

import './App.css'
import MyWorks from "./containers/MyWorks";

class App extends Component {

  componentDidMount() {
     requestQuota(() => {});
  }

  render() {
    return (
      <div className="app-container">
        <ErrorAlerts>
          <Offline />
          { this.props.loggedIn && <Nav /> }
          { this.props.loggedIn && <Container><Alerts /></Container> }
          <Switch>
            <PrivateRoute exact path="/" component={Projects} />
            <PrivateRoute exact path="/projekt/:projectId" component={Project} />
            <PrivateRoute path="/projekt/:projectId/plan/:planId" component={Plan} />
            <PrivateRoute path="/arbeiten/:planId?/:internalId?" component={Works} />
            <PrivateRoute path="/meine-arbeiten" component={MyWorks}/>
            <PrivateRoute path="/benutzer" component={Account} />
            <Route path="/anmelden" component={Login} />
          </Switch>
          { this.props.loggedIn && <Footer /> }
        </ErrorAlerts>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.user.user !== null,
});

// export default TodoList;
export default withRouter(connect(mapStateToProps)(App));
