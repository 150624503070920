const workTypes = [
  {
    name: 'Weichschott',
    canCreateInApp: true,
    fields: [
      {
        name: 'softchott_separation',
        label: 'Abschottung',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Einplattenschott EI 60', text: 'Einplattenschott EI 60', value: 'Einplattenschott EI 60' },
          { key: 'Zweiplattenschott EI 90', text: 'Zweiplattenschott EI 90', value: 'Zweiplattenschott EI 90' },
          { key: 'RF 1', text: 'RF 1', value: 'RF 1' },
          { key: 'Schall', text: 'Schall', value: 'Schall' },
          { key: 'Reparatur', text: 'Reparatur', value: 'Reparatur' }
        ]
      },
      {
        name: 'softchott_system',
        label: 'System',
        type: 'select',
        rules: 'required',
        defaultValueKey: 'default_softchott_system',
        options: [
          { key: 'Hensel ABL', text: 'Hensel ABL', value: 'Hensel ABL' },
          { key: 'BC Chemie DSB', text: 'BC Chemie DSB', value: 'BC Chemie DSB' },
          { key: 'KBS ABL', text: 'KBS ABL', value: 'KBS ABL' },
          { key: 'KBS DSB', text: 'KBS DSB', value: 'KBS DSB' },
          { key: 'Mulcol Multisealant', text: 'Mulcol Multisealant', value: 'Mulcol Multisealant' }
        ]
      },
      {
        name: 'softchott_location',
        label: 'Lage',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Decke', text: 'Decke', value: 'Decke' },
          { key: 'Wand', text: 'Wand', value: 'Wand' },
          { key: 'Boden', text: 'Boden', value: 'Boden' },
          { key: 'Divers', text: 'Divers', value: 'Divers' },
        ]
      },
      {
        name: 'softchott_content',
        label: 'Inhalt',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Rohr', text: 'Rohr', value: 'Rohr' },
          { key: 'Elektro', text: 'Elektro', value: 'Elektro' },
          { key: 'Lüftung', text: 'Lüftung', value: 'Lüftung' },
          { key: 'Leer', text: 'Leer', value: 'Leer' },
          { key: 'Kombi', text: 'Kombi', value: 'Kombi' },
        ]
      },
      {
        name: 'softchott_length',
        label: 'Länge',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'softchott_width',
        label: 'Breite',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'softchott_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      }
    ]
  },
  {
    name: 'Mörtelschott',
    canCreateInApp: true,
    fields: [
      {
        name: 'mortarchott_location',
        label: 'Lage',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Decke', text: 'Decke', value: 'Decke' },
          { key: 'Wand', text: 'Wand', value: 'Wand' },
          { key: 'Boden', text: 'Boden', value: 'Boden' },
          { key: 'Divers', text: 'Divers', value: 'Divers' },
        ]
      },
      {
        name: 'mortarchott_content',
        label: 'Inhalt',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Rohr', text: 'Rohr', value: 'Rohr' },
          { key: 'Elektro', text: 'Elektro', value: 'Elektro' },
          { key: 'Lüftung', text: 'Lüftung', value: 'Lüftung' },
          { key: 'Leer', text: 'Leer', value: 'Leer' },
          { key: 'Kombi', text: 'Kombi', value: 'Kombi' },
        ]
      },
      {
        name: 'mortarchott_length',
        label: 'Länge',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'mortarchott_width',
        label: 'Breite',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'mortarchott_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      },
      {
        name: 'mortarchott_extra_depth',
        label: 'Extra Tiefe',
        type: 'boolean',
      },
      {
        name: 'mortarchott_isolated',
        label: 'Isoliert',
        type: 'boolean',
      },
    ]
  },
  {
    name: 'Manschette',
    canCreateInApp: true,
    fields: [
      {
        name: 'collar_type',
        label: 'Typ',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'AWM 3 light', text: 'AWM 3 light', value: 'AWM 3 light' },
          { key: 'AWM 2 KS', text: 'AWM 2 KS', value: 'AWM 2 KS' },
          { key: 'Doyma', text: 'Doyma', value: 'Doyma' },
          { key: 'KBS M', text: 'KBS M', value: 'KBS M' },
          { key: 'Mulcol', text: 'Mulcol', value: 'Mulcol' },
          { key: 'Unicollar', text: 'Unicollar', value: 'Unicollar' },
          { key: 'Flüssigmanschette', text: 'Flüssigmanschette', value: 'Flüssigmanschette' },
          { key: 'Pacyfire', text: 'Pacyfire', value: 'Pacyfire' },
          { key: 'Hensotherm 7KS 50mm', text: 'Hensotherm 7KS 50mm', value: 'Hensotherm 7KS 50mm' },
        ]
      },
      {
        name: 'collar_size',
        label: 'Größe',
        type: 'select',
        rules: 'required',
        options: [
          { key: '32', text: '32 Ø', value: '32' },
          { key: '40', text: '40 Ø', value: '40' },
          { key: '56', text: '56 Ø', value: '56' },
          { key: '63', text: '63 Ø', value: '63' },
          { key: '75', text: '75 Ø', value: '75' },
          { key: '90', text: '90 Ø', value: '90' },
          { key: '110', text: '110 Ø', value: '110' },
          { key: '125', text: '125 Ø', value: '125' },
          { key: '140', text: '140 Ø', value: '140' },
          { key: '160', text: '160 Ø', value: '160' },
          { key: '180', text: '180 Ø', value: '180' },
          { key: '200', text: '200 Ø', value: '200' },
          { key: '225', text: '225 Ø', value: '225' },
          { key: '250', text: '250 Ø', value: '250' },
          { key: '280', text: '280 Ø', value: '280' },
          { key: '300', text: '300 Ø', value: '300' },
          { key: '315', text: '315 Ø', value: '315' },
          { key: '350', text: '350 Ø', value: '350' },
          { key: '400', text: '400 Ø', value: '400' },
        ]
      },
      {
        name: 'collar_content',
        label: 'Inhalt',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Rohr', text: 'Rohr', value: 'Rohr' },
          { key: 'Elektro', text: 'Elektro', value: 'Elektro' },
        ]
      },
      {
        name: 'collar_location',
        label: 'Lage',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Decke', text: 'Decke', value: 'Decke' },
          { key: 'Wand', text: 'Wand', value: 'Wand' },
          { key: 'Boden', text: 'Boden', value: 'Boden' },
          { key: 'Divers', text: 'Divers', value: 'Divers' },
        ]
      },
      {
        name: 'collar_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      },
    ]
  },
  {
    name: 'Isolation',
    canCreateInApp: true,
    fields: [
      {
        name: 'isolation_medium',
        label: 'Medium',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Warm', text: 'Warm', value: 'Warm' },
          { key: 'Kalt', text: 'Kalt', value: 'Kalt' },
          { key: '7 KS Bandage', text: '7 KS Bandage', value: '7 KS Bandage' },
          { key: 'Lüftungsdämmung', text: 'Lüftungsdämmung', value: 'Lüftungsdämmung' },
        ]
      },
      {
        name: 'isolation_diameter',
        label: 'Rohrdurchmesser',
        type: 'select',
        rules: 'required',
        options: [
          { key: '6', text: '6mm Ø', value: '6' },
          { key: '8', text: '8mm Ø', value: '8' },
          { key: '10', text: '10mm Ø', value: '10' },
          { key: '12', text: '12mm Ø', value: '12' },
          { key: '15', text: '15mm Ø', value: '15' },
          { key: '16', text: '16mm Ø', value: '16' },
          { key: '18', text: '18mm Ø', value: '18' },
          { key: '20', text: '20mm Ø', value: '20' },
          { key: '22', text: '22mm Ø', value: '22' },
          { key: '25', text: '25mm Ø', value: '25' },
          { key: '28', text: '28mm Ø', value: '28' },
          { key: '30', text: '30mm Ø', value: '30' },
          { key: '32', text: '32mm Ø', value: '32' },
          { key: '35', text: '35mm Ø', value: '35' },
          { key: '38', text: '38mm Ø', value: '38' },
          { key: '40', text: '40mm Ø', value: '40' },
          { key: '42', text: '42mm Ø', value: '42' },
          { key: '45', text: '45mm Ø', value: '45' },
          { key: '48', text: '48mm Ø', value: '48' },
          { key: '50', text: '50mm Ø', value: '50' },
          { key: '54', text: '54mm Ø', value: '54' },
          { key: '60', text: '60mm Ø', value: '60' },
          { key: '64', text: '64mm Ø', value: '64' },
          { key: '70', text: '70mm Ø', value: '70' },
          { key: '76', text: '76mm Ø', value: '76' },
          { key: '80', text: '80mm Ø', value: '80' },
          { key: '89', text: '89mm Ø', value: '89' },
          { key: '114', text: '114mm Ø', value: '114' },
          { key: '140', text: '140mm Ø', value: '140' },
          { key: '168', text: '168mm Ø', value: '168' },
          { key: '219', text: '219mm Ø', value: '219' },
          { key: '245', text: '245mm Ø', value: '245' },
          { key: '273', text: '273mm Ø', value: '273' },
          { key: '305', text: '305mm Ø', value: '305' },
          { key: '324', text: '324mm Ø', value: '324' },
          { key: '356', text: '356mm Ø', value: '356' },
          { key: '406', text: '406mm Ø', value: '406' },
          { key: '457', text: '457mm Ø', value: '457' },
          { key: '508', text: '508mm Ø', value: '508' },
        ]
      },
      {
        name: 'isolation_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      },
    ]
  },
  {
    name: 'Bekleidung',
    canCreateInApp: true,
    fields: [
      {
        name: 'cover_type',
        label: 'Typ',
        type: 'text',
        rules: 'required',
      },
      {
        name: 'cover_unit',
        label: 'Einheit',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'qm', text: 'qm', value: 'qm' },
          { key: 'lfm', text: 'lfm', value: 'lfm' }
        ]
      },
      {
        name: 'cover_size',
        label: 'Größe',
        type: 'number',
        steps: '1',
        rules: 'required',
      },
      {
        name: 'cover_material',
        label: 'Material',
        type: 'text',
        rules: 'required',
      },
      {
        name: 'cover_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      },
    ]
  },
  {
    name: 'Fuge',
    canCreateInApp: true,
    fields: [
      {
        name: 'joint_length',
        label: 'Länge',
        type: 'number',
        steps: '0.01',
        unit: 'm',
        rules: 'required',
      },
      {
        name: 'joint_width',
        label: 'Breite',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'joint_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      }
    ]
  },
  {
    name: 'Kabel-Box',
    canCreateInApp: true,
    fields: [
      {
        name: 'cablebox_type',
        label: 'Typ',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'FST Kabelbox', text: 'FST Kabelbox', value: 'FST Kabelbox' },
          { key: 'Wichmann Kabelbox', text: 'Wichmann Kabelbox', value: 'Wichmann Kabelbox' },
          { key: 'CT Pyrosafe Typ 150', text: 'CT Pyrosafe Typ 150', value: 'CT Pyrosafe Typ 150' },
          { key: 'CT Pyrosafe Typ 200', text: 'CT Pyrosafe Typ 200', value: 'CT Pyrosafe Typ 200' },
          { key: 'CT Pyrosafe Typ 300', text: 'CT Pyrosafe Typ 300', value: 'CT Pyrosafe Typ 300' }
        ]
      },
      {
        name: 'cablebox_length',
        label: 'Länge',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'cablebox_width',
        label: 'Breite',
        type: 'number',
        steps: '0.01',
        unit: 'cm',
        rules: 'required',
      },
      {
        name: 'cablebox_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      }
    ]
  },
  {
    name: 'Freie Position',
    canCreateInApp: true,
    fields: [
      {
        name: 'blank_title',
        label: 'Titel',
        type: 'text',
        rules: 'required',
      },
      {
        name: 'blank_quantity',
        label: 'Anzahl',
        type: 'number',
        unit: 'Stück',
        rules: 'required',
      }
    ]
  },
  {
    name: 'Regierapport',
    canCreateInApp: true,
    fields: [
      {
        name: 'rapport_title',
        label: 'Titel',
        type: 'select',
        rules: 'required',
        options: [
          { key: 'Servicemonteur inkl. Material', text: 'Servicemonteur inkl. Material', value: 'Servicemonteur inkl. Material' },
          { key: 'Servicemonteur', text: 'Servicemonteur', value: 'Servicemonteur' },
          { key: 'Brandschutzmonteur A', text: 'Brandschutzmonteur A', value: 'Brandschutzmonteur A' },
          { key: 'Brandschutzmonteur B', text: 'Brandschutzmonteur B', value: 'Brandschutzmonteur B' },
          { key: 'Vorarbeiter', text: 'Vorarbeiter', value: 'Vorarbeiter' },
          { key: 'Chefmonteur', text: 'Chefmonteur', value: 'Chefmonteur' },
          { key: 'Reserve Position', text: 'Reserve Position', value: 'Reserve Position' },
          { key: 'Regierapport', text: 'Regierapport', value: 'Regierapport' },
          { key: 'Erschwerung', text: 'Erschwerung', value: 'Erschwerung' },
          { key: 'Bereitstellung bis 10km', text: 'Bereitstellung bis 10km', value: 'Bereitstellung bis 10km' },
          { key: 'Bereitstellung ab 10km', text: 'Bereitstellung ab 10km', value: 'Bereitstellung ab 10km' },
        ]
      },
      {
        name: 'rapport_quantity',
        label: 'Aufwand',
        type: 'number',
        unit: 'Stunden',
        rules: 'required',
      }
    ]
  },
];

export default workTypes;

export const getWorkTypeFields = (name) => {
  for (var workType of workTypes) {
    if (workType.name === name) {
      return workType.fields;
    }
  }
  return [];
}

export const creatableWorkTypes = () => {
  return workTypes.filter(type => type.canCreateInApp);
}
