import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { Router } from "react-router";
import * as serviceWorker from './serviceWorker';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import { Provider } from "react-redux";
import { store, persistor, history } from "./store";

import App from "./App";

const bugsnagClient = bugsnag('6ab46254b37152769e3edb8dee7eafd6');
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const rootElement = document.getElementById("root");
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <App />
          </Router>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  rootElement
);


serviceWorker.register({
  onUpdate: reg => {
    alert('Eine neue App-Version ist verfügbar. Bitte aktualisieren Sie die App um ein Datenverlust zu vermeiden!');

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
    }

    window.location.reload(true);
  }
});
