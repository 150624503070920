import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Menu, Input } from 'semantic-ui-react'

export default ({project, plans, offlinePlanIds}) => {
  const [search, setSearch] = useState('');

  return (
    <Menu vertical fluid>
      <Menu.Item>
        <h4>{plans.length} Pläne</h4>
      </Menu.Item>
      <Menu.Item>
        <Input
          icon='search'
          placeholder='Plan suchen...'
          value={search}
          onChange={ event => setSearch(event.target.value) }
       />
      </Menu.Item>
      {plans.map(p => {
        if( search === '' || RegExp(search, 'i').test(p.name) ) {
          return (
            <Menu.Item key={ 'plans'+p.id } as={Link} to={'/projekt/'+project.id+'/plan/'+p.id} >
              {p.name} <span className="entry-id">#{p.id}</span>
              {offlinePlanIds && offlinePlanIds.includes(p.id) && <div className="ui green label"><i aria-hidden="true" className="check icon"></i> Offline verfügbar</div>}
            </Menu.Item>
          );
        } else return null;
      })}
    </Menu>
  )
};
