import React from 'react';
import { Menu } from 'semantic-ui-react'
import { creatableWorkTypes } from '../../../services/workTypes';

export default ({current, selected}) => {
  window.scrollTo(0,0);

  return (
    <div>
      <div className="step-headline"><strong>Schritt 2:</strong> Arbeit auswählen...</div>
      <Menu vertical fluid>
        {creatableWorkTypes().map(w => (
          <Menu.Item key={'type-'+w.name} active={ current && current.name === w.name } onClick={ () => selected(w) } >
            {w.name}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
}
