import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';

import PlansList from '../../components/PlansList'

class Project extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    const project = this.props.project;
    const offlinePlanIds = this.props.offlinePlanIds;
    if( project ) {
      return (
        <Container>
          <h2 style={{ textAlign: 'center' }}>{project.name}</h2>

          <PlansList
            project={project}
            plans={project.plans}
            offlinePlanIds={offlinePlanIds}
          />

          <p className="project-description">{project.description}</p>
        </Container>
      );
    } else return this.notFound();

  }

  notFound() {
    return (
      <Container>
        <div>Projekt nicht gefunden</div>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  project: state.projects.byIds[ownProps.match.params.projectId],
  offlinePlanIds: state.projects.offlinePlanIds
});

// export default TodoList;
export default connect(mapStateToProps)(Project);
