import * as types from "../actionTypes";
import { removeObjectField } from "../services/helpers";

const initialState = {
  loading: false,
  allIds: [],
  byIds: {},
  workEdit: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_WORKS: {
      const { works } = action;

      let allIds = [];
      let byIds = {};

      works.forEach(project => {
        allIds.push(project.id);
        byIds[project.id] = project;
      });

      return {
        ...state,
        allIds: allIds,
        byIds: byIds,
      };
    }
    case types.FETCH_WORKS_STARTED: {
      return {
        ...state,
        loading: true,
      }
    }
    case types.FETCH_WORKS_SUCCESSFUL: {
      return {
        ...state,
        loading: false,
      }
    }
    case types.FETCH_WORKS_FAILED: {
      return {
        ...state,
        loading: false,
      }
    }
    case types.SET_EDITED_WORK: {
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload
        },
      }
    }

    case types.SET_WORK_EDIT: {
      const { work } = action;

      return {
        ...state,
        loading: false,
        workEdit: work
      }
    }
    case types.WORK_UPLOAD_SUCCESSFUL: {
      return {
        ...state,
        allIds: [
          ...state.allIds,
          action.payload.id
        ],
        byIds: {
          ...state.byIds,
          [action.payload.id]: action.payload
        },
      }
    }
    case types.SOLVE_WORK_STARTED: {
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.workId]: {
            ...state.byIds[action.workId],
            uploading: true
          }
        }
      }
    }
    case types.SOLVE_WORK_SUCCESSFUL: {
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.workId]: {
            ...action.payload,
            uploading: false
          }
        }
      }
    }
    case types.SOLVE_WORK_FAILED: {
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [action.workId]: {
            ...state.byIds[action.workId],
            uploading: false
          }
        }
      }
    }

    case types.CLEAR_WORKS: {
      return {
        ...state,
        allIds: [],
        byIds: {},
      }
    }

    case types.WORK_DELETE_SUCCESSFUL: {
      return {
        ...state,
        allIds: state.allIds.filter( id => id !== action.workId ),
        byIds: removeObjectField(state.byIds, action.workId),
      }
    }
    case types.LOGOUT: {
      return initialState
    }
    default:
      return state;
  }
}
