/* PROJECTS */
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_STARTED = 'FETCH_PROJECTS_STARTED';
export const FETCH_PROJECTS_SUCCESSFUL = 'FETCH_PROJECTS_SUCCESSFUL';
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SWITCH_PLAN_WORKS_OFFLINE = 'SWITCH_PLAN_WORKS_OFFLINE';
export const UPDATE_OFFLINE_WORKS_FOR_PLAN = 'UPDATE_OFFLINE_WORKS_FOR_PLAN';

/* WORKS */
export const FETCH_WORKS = 'FETCH_WORKS';
export const SET_MY_WORKS = 'SET_MY_WORKS';
export const FETCH_WORKS_STARTED = 'FETCH_WORKS_STARTED';
export const FETCH_WORKS_SUCCESSFUL = 'FETCH_WORKS_SUCCESSFUL';
export const FETCH_WORKS_FAILED = 'FETCH_WORKS_FAILED';
export const SET_WORKS = 'SET_WORKS';
export const DELETE_WORK = 'DELETE_WORK';

export const FETCH_WORK = 'FETCH_WORK';
export const SET_WORK_EDIT = 'SET_WORK_EDIT';
export const FETCH_WORK_FOR_PLAN = 'FETCH_WORK_FOR_PLAN';

/* MY WORKS */
export const FETCH_MY_WORKS = 'FETCH_MY_WORKS';
export const FETCH_MY_WORKS_STARTED = 'FETCH_MY_WORKS_STARTED';
export const FETCH_MY_WORKS_SUCCESSFUL = 'FETCH_MY_WORKS_SUCCESSFUL';
export const FETCH_MY_WORKS_FAILED = 'FETCH_MY_WORKS_FAILED';

/* WORKS ADD*/
export const NEW_WORK = 'NEW_WORK';
export const ADD_WORK = 'ADD_WORK';
export const UPLOAD_WORK = 'UPLOAD_WORK';
export const WORK_UPLOAD_STARTED = 'WORK_UPLOAD_STARTED';
export const WORK_UPLOAD_SUCCESSFUL = 'WORK_UPLOAD_SUCCESSFUL';
export const WORK_UPLOAD_FAILED = 'WORK_UPLOAD_FAILED';
export const WORK_DELETE_SUCCESSFUL = 'WORK_DELETE_SUCCESSFUL';
export const WORK_DELETE_FAILED = 'WORK_DELETE_FAILED';
export const PENDING_UPDATE_CREATED = 'PENDING_UPDATE_CREATED'
export const SET_PENDING_UPDATE_CREATED = 'SET_PENDING_UPDATE_CREATED';

// WORKS EDITING
export const ADD_EDITED_WORK = 'ADD_EDITED_WORK';
export const SET_EDITED_WORK = 'SET_EDITED_WORK';
export const EDITED_WORK_UPLOAD_STARTED = 'EDITED_WORK_UPLOAD_STARTED';
export const EDITED_WORK_UPLOAD_SUCCESSFUL = 'EDITED_WORK_UPLOAD_SUCCESSFUL';
export const EDITED_WORK_UPLOAD_FAILED = 'EDITED_WORK_UPLOAD_FAILED';
export const UPDATE_WORK = 'UPDATE_WORK';

export const MARK_AS_SOLVED = 'MARK_AS_SOLVED';
export const SOLVE_WORK_SUCCESSFUL = 'SOLVE_WORK_SUCCESSFUL';
export const SOLVE_WORK_FAILED = 'SOLVE_WORK_FAILED';
export const SOLVE_WORK_STARTED = 'SOLVE_WORK_STARTED';
export const CLEAR_WORKS = 'CLEAR_WORKS';

/* USER */
export const LOGIN = 'LOGIN';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGOUT = 'LOGOUT';
export const LOGGED_OUT = 'LOGGED_OUT';

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const ERROR_ALERT = 'ERROR_ALERT';

export const ONLINE_STATE_CHANGED = 'ONLINE_STATE_CHANGED';
