import React, { Component } from 'react';
import { Button, Icon, Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import './styles.css'

import * as actionTypes from '../../actionTypes';

class Account extends Component {

  render() {
    return (
      <Container className="account-container">
        <div className="account-username">{this.props.user.user.name}</div>
        <div className="account-email">{this.props.user.user.email}</div>

        <Button icon primary labelPosition='left' onClick={ () => window.confirm('Möchten Sie sich wirklich abmelden? (Arbeiten die auf den Upload warten, falls Sie offline sind, werden gelöscht!)') && this.props.logout() } >
          <Icon name='power off' />
          Abmelden
        </Button>
      </Container>
    );
  }

  submit() {
    if (this.validator.allValid()) {
      this.props.login(this.state);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    logout: data => dispatch({ type: actionTypes.LOGOUT }),
  }
}

// export default TodoList;
export default connect(mapStateToProps, mapDispatchToProps)(Account);
