import React from 'react';
import {Button, Icon, Segment, Message, Grid, Card, Responsive} from 'semantic-ui-react'
import * as actionTypes from "../../../actionTypes";
import { connect } from "react-redux";
import {UPDATE_OFFLINE_WORKS_FOR_PLAN} from "../../../actionTypes";
import { setWorks } from '../../../actions/works';

const createButtonBreakpoint = 700;

class Position extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.setOfflineWorks(this.props.plan);
    this.props.fetchWorks(this.props.plan);
  }

  constructor(props) {
    super(props);
    if(props.position) {
      this.state = props.position
    } else {
      this.state = { x: null, y: null }
    }
  }

  _onMouseMove(e) {
    const x = e.nativeEvent.offsetX / e.target.offsetWidth;
    const y = e.nativeEvent.offsetY / e.target.offsetHeight;

    this.setState({
      x: x,
      y: y,
      selectedPointId: null,
    })
  }

  render() {
    const works =this.props.works;
    return (
      <div>
        <div className="step-headline"><strong>Schritt 1:</strong> Position auswählen...</div>

        <Segment>
          <div style={{width: '100%', position: 'relative'}}>
            {this.renderPoint()}
            {this.renderExistingPoints(works)}
            <img alt="Bauplan" onClick={this._onMouseMove.bind(this)} style={{width: '100%', cursor: 'pointer'}} src={this.props.image} />
          </div>

          {!this.props.canAddMore &&
          <Message negative>
            <Message.Header>Zu viele wartende Arbeiten...</Message.Header>
            <p>Bitte stellen Sie eine Verbindung zum Internet her um wartende Arbeiten hochzuladen. Das Eintragen von weiteren Arbeiten wird verhindert, da es im Offline-Modus sonst zu Datenverlust führen kann.</p>
          </Message>
          }

          <Card.Content>
            <Grid>
              <Responsive as={Grid.Row}columns={3} minWidth={createButtonBreakpoint+1} style={{marginTop: 30, marginBottom: 20}}>
                <Grid.Column style={{'text-align': 'center', paddingTop: 8}}>
                    <strong>Neue Arbeit eintragen</strong>
                </Grid.Column>
                <Grid.Column>
                  <Button icon primary fluid labelPosition='right' disabled={!this.props.canAddMore || (this.state.x == null || this.state.y == null)} onClick={ this.apply.bind(this, 'invoice') }>
                    <Icon name='add' />
                    Rechnung
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button icon primary fluid labelPosition='right' disabled={!this.props.canAddMore || (this.state.x == null || this.state.y == null)} onClick={ this.apply.bind(this, 'offer') }>
                    <Icon name='add' />
                    Angebot
                  </Button>
                </Grid.Column>
              </Responsive>
              <Responsive as={Grid.Row} columns={1} maxWidth={createButtonBreakpoint} style={{marginTop: 30, paddingBottom: 0}}>
                <Grid.Column style={{'text-align': 'center'}}>
                  <strong>Neue Arbeit eintragen</strong>
                </Grid.Column>
              </Responsive>
              <Responsive as={Grid.Row} columns={2} maxWidth={createButtonBreakpoint} style={{marginBottom: 20}}>
                <Grid.Column>
                  <Button icon primary fluid labelPosition='right' disabled={!this.props.canAddMore || (this.state.x == null || this.state.y == null)} onClick={ this.apply.bind(this, 'invoice') }>
                    <Icon name='add' />
                    Rechnung
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button icon primary fluid labelPosition='right' disabled={!this.props.canAddMore || (this.state.x == null || this.state.y == null)} onClick={ this.apply.bind(this, 'offer') }>
                    <Icon name='add' />
                    Angebot
                  </Button>
                </Grid.Column>
              </Responsive>
            </Grid>
          </Card.Content>
          <Button style={{marginTop: 10}} icon fluid secondary labelPosition='right' onClick={ this.showWorksForPlan.bind(this) }>
            <Icon name='map' />
            Zeige Arbeiten für diesen Plan
          </Button>

          <Button style={{marginTop: 10}} icon fluid secondary labelPosition='right' disabled={!this.state.selectedPointId} onClick={ this.showWorksForPoint.bind(this) }>
            <Icon name='map pin' />
            Zeige Arbeiten für diese Position
          </Button>
          {this.getAvailableOfflineButton()}
        </Segment>
      </div>
    )
  }

  showWorksForPoint() {
    this.props.onShowWorksForPoint(this.state.selectedPointId);
  }

  showWorksForPlan() {
    this.props.onShowWorksForPlan();
  }

  scaledPointStyles(point) {
    return {
      left: point.position_x*100+'%',
      top: point.position_y*100+'%',
      fontSize: (this.props.fontSize || 6) +'px',
      minHeight: this.props.fontSize * 1.3,
      minWidth: this.props.fontSize * 1.3,
    };
  }

  handleExistingPointClick(work) {
    this.setState({
      x: work.position_x,
      y: work.position_y,
      selectedPointId: work.internal_id,
    });
  }

  renderExistingPoints(works) {
    let points = {};

    for (let point of works) {
      // Has internal ID and position
      if(!points.hasOwnProperty(point.internal_id) && ( point.position_x !== null || point.position_y !== null)) {
        points[point.internal_id] = point;
      }
    }

    // Add pending points
    for(var key of Object.keys(this.props.pendingWorks.works)) {
      var work = this.props.pendingWorks.works[key];

      // Add the point if the plan
      if(work.plan_id === this.props.planId) {
        points['temp_'+key] = {
          ...work,
          temp: true
        };
      }
    }

    return Object.keys(points).map(workId => (
      <button
        key={ 'position-existing-point'+workId}
        className={this.getButtonClasses(points, workId)}
        style={ this.scaledPointStyles(points[workId]) }
        onClick={ () => this.handleExistingPointClick(points[workId]) }
      ><span>{points[workId].internal_id}</span></button>
    ));
  }

  renderPoint() {
    if( this.state.x && this.state.y ) {
      const top = this.state.y * 100 + '%';
      const left = this.state.x * 100 + '%';
      return (
        <div className={'position-point'} style={{top: top, left: left}} />
      );
    } else return null;
  }

  getButtonClasses(points, workId) {
    const styles = ['position-existing-point'];

    if (points[workId].position_x === this.state.x && points[workId].position_y === this.state.y) styles.push('active');
    if (points[workId].temp) styles.push('temp');
    if (points[workId].target === 'invoice') {
      styles.push('invoice')
    } else {
      if (points[workId].done_at == null) {
        styles.push('offer')
      } else {
        styles.push('invoice')
      }
    }
    if (points[workId].additional_position) styles.push('position-point-additional');
    if (points[workId].calculation === 'management') styles.push('position-point-management');
    if (points[workId].wisa) styles.push('position-point-wisa')

    return styles.join(' ')
  }

  apply(target) {
    this.props.selected(this.state, target);
  }

  getAvailableOfflineButton() {
    if (this.props.offlinePlanIds && this.props.offlinePlanIds.includes(this.props.plan.id)) {
      return (<Button style={{marginTop: 30}} icon fluid negative labelPosition='right' onClick={() => this.props.switchPlanWorksOffline(this.props.plan, this.props.works, this.props.offlinePlanIds)}>
        <Icon name='close' />
        Nicht mehr offline nutzen
      </Button>)
    } else {
      return (<Button style={{marginTop: 30}} icon fluid positive labelPosition='right' onClick={() => this.props.switchPlanWorksOffline(this.props.plan, this.props.works, this.props.offlinePlanIds)}>
        <Icon name='arrow down' />
        Für Offline-Nutzung herunterladen
      </Button>)
    }
  }
}

const mapStateToProps = state => {
  return {
    works: Object.values(state.works.byIds),
    offlinePlanIds: state.projects.offlinePlanIds
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchWorks: (plan) => dispatch({
      type: actionTypes.FETCH_WORK_FOR_PLAN,
      planId: plan.id
    }),
    setOfflineWorks: plan => dispatch( setWorks(plan.works || []) ),
    switchPlanWorksOffline: (plan, works, offlinePlanIds) => {
      if (!offlinePlanIds || !offlinePlanIds.includes(plan.id)) {
        //populate plan with works for offline
        dispatch({
          type: UPDATE_OFFLINE_WORKS_FOR_PLAN,
          payload: { plan: {...plan, works: works}, deletePlan: false }
        });
      } else {
        //delete works from plan
        const planWithWorks = {...plan, works: []};
        dispatch({
          type: UPDATE_OFFLINE_WORKS_FOR_PLAN,
          payload: { plan: planWithWorks, deletePlan: true }
        });
      }
    }

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Position);
