import React, {useEffect} from 'react';
import {Container, Visibility} from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../actionTypes";
import WorksList from "../../components/WorksList";
import PendingWorksList from "../../components/PendingWorksList";
import { editWork } from "../../actions/works";

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: actionTypes.FETCH_MY_WORKS })
  }, []);

  const { myWorks, user, loading, pendingEditedWorks, pendingWorks } = useSelector(state => ({
    myWorks: state.myWorks.all,
    user: state.user.user,
    loading: state.myWorks.loading,
    pendingEditedWorks: state.pendingWorks.editedWorks,
    pendingWorks: state.pendingWorks.works,
  }));

  return (
    <Container>
      {Object.keys(pendingWorks).length > 0 &&
      <PendingWorksList
        works={pendingWorks}
        user={user}
        editWork={(workId) => {dispatch(editWork(workId))}}
        uploadWork={() => dispatch({ type: actionTypes.UPLOAD_WORK })}
      />
      }
      <WorksList
        user={user}
        works={myWorks}
        loading={loading}
        deleteWork={(workId) => dispatch({ type: actionTypes.DELETE_WORK, workId: workId })}
        editWork={(workId) => {dispatch(editWork(workId))}}
        markAsSolved={(work, fileId) => dispatch({ type: actionTypes.MARK_AS_SOLVED, workId: work.id, fileId: fileId })}
        pendingEditedWorks={pendingEditedWorks}
      />
      <Visibility
        as="tbody"
        continuous={false}
        once={false}
        onBottomVisible={() => { dispatch({ type: actionTypes.FETCH_MY_WORKS, length: myWorks.length })}}
      >
      </Visibility>
    </Container>
  )
};
