import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';

import ProjectsList from '../../components/ProjectsList'
import * as actionTypes from '../../actionTypes';

class Projects extends Component {

  componentDidMount() {
    this.props.fetchProjects();
    window.scrollTo(0,0);
  }

  render() {
    return (
      <Container>
          <ProjectsList
            projects={this.props.projects}
          />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProjects: () => dispatch({ type: actionTypes.FETCH_PROJECTS }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
