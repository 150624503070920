import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux';
import './styles.css'

class Offline extends Component {

  render() {
    if( !this.props.online ) {
      return (
        <div className="offline-message">
          <Icon name='sync' loading={ this.props.pendingWorksCount > 0 || this.props.pendingUpdatedWorksCount } />
          Offline.
          {this.props.pendingWorksCount > 0 && <span> {this.props.pendingWorksCount} Arbeiten warten auf Upload...</span>}
          {this.props.pendingUpdatedWorksCount > 0 && <span> {this.props.pendingUpdatedWorksCount} aktualisierte Arbeiten warten auf den Upload...</span>}
        </div>
      )
    } else return null;
  }
}

const mapStateToProps = state => ({
  online: state.online,
  pendingWorksCount: state.pendingWorks.works ? Object.keys(state.pendingWorks.works).length : 0,
  pendingUpdatedWorksCount:  state.pendingWorks.editedWorks ? Object.keys(state.pendingWorks.editedWorks).length : 0,
});

// export default TodoList;
export default connect(mapStateToProps)(Offline);
