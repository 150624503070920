import React from 'react';
import { List } from 'semantic-ui-react'
import WorkWidget from '../WorkWidget'

export default ({works, user, uploadWork, editWork}) => (
  <List divided relaxed>
    {Object.keys(works)
      .sort((a,b) => new Date(works[b].created_at)-new Date(works[a].created_at))
      .map(wId => <WorkWidget key={'works-list-'+wId}
                              work={works[wId]}
                              user={user}
                              workIdTemp={wId}
                              uploadWork={ uploadWork }
                              editWork={editWork}/> )}
  </List>
);
