import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Switch } from "react-router-dom";
import { matchPath } from 'react-router';
import SelectType from './SelectType'
import Position from './Position'
import EditInfo from './EditInfo'
import * as actionTypes from '../../actionTypes';
import * as actionsModifications from '../../actionModifications'

class Plan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      workType: null,
      position: null,
      defaultTarget: null,
    }
  }

  render() {
    const { project, plan } = this.props;

    if(!this.proof()) return null;

    if( project ) {
      return (
        <Container>
          <h5 className="steps-project-name">{project.name}</h5>
          <h2 className="steps-plan-name">{plan.name}</h2>
          <Switch>
            <Position exact path="/projekt/:projectId/plan/:planId"
              pendingWorks={this.props.pendingWorks}
              canAddMore={this.props.canAddMore}
              image={ plan.publicFile }
              planId={ plan.id }
              plan={ plan }
              selected={ this.selectedPosition.bind(this) }
              onShowWorksForPoint={ this.onShowWorksForPoint.bind(this) }
              onShowWorksForPlan={ this.onShowWorksForPlan.bind(this) }
              position={this.state.position} fontSize={ project.point_font_size } />
            <SelectType
              exact
              path="/projekt/:projectId/plan/:planId/arbeit"
              selected={ this.selectedWorkType.bind(this) }
              current={ this.state.workType }
            />
            <EditInfo
              exact
              path="/projekt/:projectId/plan/:planId/info"
              uploading={this.props.uploadingWork}
              workType={this.state.workType}
              defaultTarget={this.state.defaultTarget}
              submit={ this.submit.bind(this) }
              canAddMore={this.props.canAddMore}
            />
            <EditInfo
              exact
              path="/projekt/:projectId/plan/:planId/edit/:workId"
              uploading={this.props.uploadingWork}
              update={ this.update.bind(this) }
              getWork={this.props.getWork}
              worksByIds={this.props.worksByIds}
              pendingWorks={this.props.pendingWorks.works}
              editType={true}
              user={this.props.user}
              myWorks={this.props.myWorks}
            />
          </Switch>
        </Container>
      );
    } else return this.notFound();

  }

  proof() {
    const stepType = !!matchPath(this.props.location.pathname, '/projekt/:projektid/plan/:planid/arbeit');
    const stepInfo = !!matchPath(this.props.location.pathname, '/projekt/:projektid/plan/:planid/info');

    // On select type or info but not selected a position?
    if( ((stepType || stepInfo) && this.state.position === null) || (stepInfo && this.state.workType === null)) {
      this.goToPosition();
      return false;
    }

    return true;
  }

  goToStep(step) {
    const { project, plan } = this.props;
    this.props.history.push('/projekt/'+project.id+'/plan/'+plan.id+step);
  }

  goToPosition() {
      this.goToStep('');
  }

  selectedWorkType(item) {
      this.setState({
      workType: item
    });

    // Go to next step
    this.goToStep('/info');
  }

  onShowWorksForPoint(positionId) {
    const { plan } = this.props;
    this.props.history.push('/arbeiten/'+plan.id+'/'+positionId);
  }

  onShowWorksForPlan() {
    const { plan } = this.props;
    this.props.history.push('/arbeiten/'+plan.id);
  }

  selectedPosition(position, defaultTarget) {
    this.setState({
      position,
      defaultTarget
    });

    // Go to next step
    this.goToStep('/arbeit');
  }

  submit(data, addAnother) {
    const { project, plan } = this.props;

    const payload = {
      ...data,
      plan_id: plan.id,
      position_x: this.state.position.x,
      position_y: this.state.position.y,
      type: this.state.workType.name,
      plan: plan,
      project: project,
      created_at: new Date(),
    };

    this.props.newWork(payload, addAnother);
  }

  update(data) {
    const { project, plan } = this.props;
    const payload = {
      ...data,
      plan: plan,
      project: project,
      updated_at: new Date(),
    };


    switch (data.updateType) {
      //depend on update type recognize what reducer
      //should be dispatched
      case actionsModifications.UPDATE_PENDING_CREATED:
        this.props.pendingWorkUpdateCreated(payload);
        return;
      case "default":
        this.props.updateWork({...payload})
    }
  }

  notFound() {
    return (
      <Container>
        <div>Not found</div>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const project = state.projects.byIds[ownProps.match.params.projectId];

  let plan = null;
  // Get the plan
  project.plans.forEach( (p, id) => {
    if( p.id === parseInt(ownProps.match.params.planId) ) {
      plan = p;
    }
  });

  return {
    project: project,
    plan: plan,
    pendingWorks: state.pendingWorks,
    worksByIds: state.works.byIds,
    user: state.user.user,
    canAddMore: Object.keys(state.pendingWorks.works).length < 30,
    myWorks: state.myWorks.all
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getWork: (workId) => {
      dispatch({ type: actionTypes.FETCH_WORK, workId: workId })
    },
    newWork: (payload, addAnother) => dispatch({
      type: actionTypes.NEW_WORK,
      payload: payload,
      addAnother: addAnother,
    }),
    updateWork: (payload) => dispatch({
      type: actionTypes.UPDATE_WORK,
      payload: payload,
    }),
    pendingWorkUpdateCreated: (payload) => dispatch({
      type: actionTypes.PENDING_UPDATE_CREATED,
      payload: payload,
    }),
    clearWorks: () => dispatch({
      type: actionTypes.CLEAR_WORKS
    })
  }
};

// export default TodoList;
export default connect(mapStateToProps, mapDispatchToProps)(Plan);
