import React from 'react';
import { Divider, Container } from 'semantic-ui-react'
import preval from 'preval.macro'
import './styles.css'

export default () => (
  <Container className="footer-container">
    <Divider />
    <div className="footer">
      <div className="version-help">
        <div>Version {preval`module.exports = new Date().toLocaleString('de-DE');`}</div>
        <a href="mailto:info@allseal.ch">Hilfe anfordern</a>
      </div>
      <img alt="Footer Logo" src={ require('../../assets/logo.png')} />
    </div>
  </Container>
)
