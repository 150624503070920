import React, { Component } from 'react';
import { Container, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import PendingWorksList from '../../components/PendingWorksList'
import WorksList from '../../components/WorksList'
import * as actionTypes from '../../actionTypes';

class Works extends Component {

  constructor(props) {
    super(props);
    let routeProjectId = null;
    const routePlanId = parseInt(props.match.params.planId);
    if (routePlanId) {
      Object.values(props.projects.byIds).forEach(project => {
        project.plans.forEach(plan => {
          if ( plan.id === routePlanId ) {
            routeProjectId = project.id;
          }
        })
      });
    }

    this.state = {
      routePlanId,
      routeProjectId
    }
  }

  componentDidMount() {
    if (this.props.match.params.planId === undefined) {
      this.props.fetchWorks();
    } else {
      this.props.fetchWorksForPlan(this.props.match.params.planId);
    }
    window.scrollTo(0,0);
  }

  render() {
    return (
      <Container>
        {!!this.state.routePlanId &&
        <Button as={Link} to={`/projekt/${this.state.routeProjectId}/plan/${this.state.routePlanId}`} style={{marginBottom: 10, marginRight: 10}}><Icon name='arrow left' /> Zurück zum Plan</Button>
        }
        {Object.keys(this.props.pendingWorks).length > 0 &&
          <PendingWorksList
            works={this.props.pendingWorks}
            editWork={this.props.editWork}
            uploadWork={this.props.uploadWork}
          />
        }

        <WorksList
          user={this.props.user}
          works={this.props.works}
          loading={this.props.loading}
          deleteWork={this.props.deleteWork}
          editWork={this.props.editWork}
          markAsSolved={this.props.markAsSolved}
          pendingEditedWorks={this.props.pendingEditedWorks}
          viewWorksAll={!!(this.props.match.params.planId || this.props.match.params.internalId)}
        />
      </Container>
    );
  }

  filtered() {
    const { params } = this.props.match;

    if(params.planId) return true;
    if(params.internalId) return true;

    if (this.state.routePlanId) {
      this.setState({routePlanId: false});
    }
    return false;
  }
}

const filterWorks = (params, work) => {

  // If filters are set....
  if(params.planId && work.plan_id !== parseInt(params.planId)) return false;
  if(params.internalId && work.internal_id !== parseInt(params.internalId)) return false;

  return true;
}

const mapStateToProps = (state, ownProps) => {

  return {
    pendingWorks: state.pendingWorks.works,
    pendingEditedWorks: state.pendingWorks.editedWorks,
    loading: state.works.loading,
    user: state.user.user,
    projects: state.projects,
    works: state.works.allIds
      .map(id => state.works.byIds[id] )
      .filter( work => filterWorks(ownProps.match.params, work))
      .sort( function(a, b) { return new Date(b.created_at) - new Date(a.created_at)})
      .slice(0, 50),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchWorks: () => dispatch({ type: actionTypes.FETCH_WORKS }),
    uploadWork: () => dispatch({ type: actionTypes.UPLOAD_WORK }),
    deleteWork: (workId) => dispatch({ type: actionTypes.DELETE_WORK, workId: workId }),
    fetchWorksForPlan: (planId) => dispatch({ type: actionTypes.FETCH_WORK_FOR_PLAN, planId }),
    editWork: (workId) => {
      dispatch({ type: actionTypes.FETCH_WORK, workId: workId })
    },
    markAsSolved: (work, fileId) => dispatch({ type: actionTypes.MARK_AS_SOLVED, workId: work.id, fileId: fileId }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Works);
