import * as types from "../actionTypes";

const initialState = {
  loggingIn: false,
  user: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_STARTED:
      return {
        ...state,
        loggingIn: true,
      }

    case types.LOGIN_SUCCESSFUL:
      return {
        ...state,
        loggingIn: false,
        user: action.user,
      }

    case types.LOGIN_FAILED:
      return {
        ...state,
        loggingIn: false,
      }

    case types.LOGOUT:
      return initialState

    default:
      return state;
  }
}
