import * as types from '../actionTypes';

const initialState = true;

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ONLINE_STATE_CHANGED:
      return action.online;

    default:
      return state
  }
}
