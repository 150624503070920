import { push } from 'connected-react-router'
import { call, put, fork , select, delay } from 'redux-saga/effects'
import * as types from '../actionTypes';
import { setWorks, setWorkToEdit } from '../actions/works';
import * as api from '../services/api';
import { exception, success } from './alerts';
import { deleteFile, read } from '../services/fs';
import uuid from "uuid";

export function* fetchWorks() {
  yield put( { type: types.FETCH_WORKS_STARTED });

  try {
    const response = yield call(api.fetchWorks);
    yield put(setWorks(response.data.data));
    yield put( { type: types.FETCH_WORKS_SUCCESSFUL } );
  } catch (e) {
    yield fork(exception, e);
    console.error(e.response);
    // yield put(fetchFailed(e));
    yield put( { type: types.FETCH_WORKS_FAILED });
    return;
  }
}

export function* fetchWork(action) {
  yield put( { type: types.FETCH_WORKS_STARTED });

  try {
    const response = yield call(api.fetchWork, action.workId);

    yield put(setWorkToEdit(response.data));
    yield put( { type: types.FETCH_WORKS_SUCCESSFUL } );
  } catch (e) {
    yield fork(exception, e);
    console.error(e.response);
    // yield put(fetchFailed(e));
    yield put( { type: types.FETCH_WORKS_FAILED });
    return;
  }
}

export function* fetchWorkForPlan(action) {
  yield put( { type: types.FETCH_WORKS_STARTED });
  try {
    const response = yield call(api.fetchWorksForPlan, action.planId);
    yield put(setWorks(response.data.data));
    yield put( { type: types.FETCH_WORKS_SUCCESSFUL } );
  } catch (e) {
    yield fork(exception, e);
    console.error(e.response);
    yield put( { type: types.FETCH_WORKS_FAILED });
    return;
  }
}

export function* newWork(action) {
  try {
    const payload = action.payload;

    // Add the new work to the redux
    yield put( { type: types.ADD_WORK, tempId: uuid.v4(), payload: payload });

    yield fork(success, 'Arbeit erfolgreich angelegt', 'Die Arbeit wurde erfolgreich angelegt und wird jetzt bzw. sobald eine Internetverbindung verfügbar ist, hochgeladen. Sie können jetzt die nächste Arbeit eintragen.', 3);

    // Trigger the upload
    yield fork( uploadWork );

    if( action.addAnother) {
      // Jump to select work type
      yield put(push('/projekt/'+payload.project.id+'/plan/'+payload.plan.id+'/arbeit'));
    } else {
      yield put(push('/projekt/'+payload.project.id+'/plan/'+payload.plan.id));
    }

    setTimeout(() => window.scrollTo(0,0), 100);
  } catch (e) {
    yield fork(exception, e);
  }
}

export function* updateWork(action) {
  const payload = action.payload;

  // Add the edited work to the redux
  yield put( { type: types.ADD_EDITED_WORK, tempId: uuid.v4(), payload: payload });

  yield fork(success, 'Arbeit erfolgreich aktualisiert',
    'Die Arbeit wurde erfolgreich aktualisiert und wird nun hochgeladen oder sobald eine Internetverbindung verfügbar ist. Sie können jetzt die nächste Arbeit eingeben.', 3);

  // Trigger the upload
  yield fork( uploadWork, 'updating' );
  yield put( { type: types.SET_EDITED_WORK, payload });
  yield put(push('/meine-arbeiten'));

  setTimeout(() => window.scrollTo(0,0), 100);
}

export function* uploadWork(uploadType = 'creation') {
  const creating = uploadType === 'creation';
  let workToUpload = null;

  try {
    var key, work;
    let pendingWorks;

    if (creating) {
      pendingWorks = yield select( state => state.pendingWorks.works );
    } else {
      pendingWorks = yield select( state => state.pendingWorks.editedWorks );
    }

    const sortedKeys = Object.keys(pendingWorks).sort((a,b) => new Date(pendingWorks[a].created_at)-new Date(pendingWorks[b].created_at));

    // Are there works uploading at the moment? If yes, leave function!
    for(key of sortedKeys) {
      work = pendingWorks[key];

      // There is one work, which is currently uploading and
      // which is not longer in uploaded state than 1 minute
      if( work.uploading && ( work.uploaded_at && uploadingSinceMinutes(work.uploaded_at) < 0.1) ) return;
    }

    // Seems to be no work is uploading at the moment
    // So, let's search for the next item to upload

    for(key of sortedKeys) {
      work = pendingWorks[key];

      // Is this work already uploading?
      // Or is this work uploading since more than 1 minute?
      if( !work.uploading || ( work.uploaded_at && uploadingSinceMinutes(work.uploaded_at) > 0.1) ) {
        workToUpload = { ...work, key: key };
        break;
      }
    }

    // No work found for upload?
    if( workToUpload === null ) return;

    // Upload the found work
    if (creating) {
      yield put({type: types.WORK_UPLOAD_STARTED, tempId: workToUpload.key});
    } else {
      yield put( { type: types.EDITED_WORK_UPLOAD_STARTED, tempId: workToUpload.key } );
    }

    if( work.imageId ) {
      workToUpload.image = yield call(read, work.imageId);
    }

    let response;
    if (creating) {
      response = yield call(api.uploadWork, workToUpload);
    } else {
      response = yield call(api.updateWork, workToUpload);
    }
    //check if work already created on server
    const existsResponse =  yield call(api.isWorkCreated, workToUpload);

    if( response.status === 200 && existsResponse.data) {
      // Successful

      // Remove temp image from storage
      if(workToUpload.imageId) {
        yield fork(deleteFile, workToUpload.imageId);
      }

      if (creating) {
        yield put({type: types.WORK_UPLOAD_SUCCESSFUL, tempId: workToUpload.key, payload: response.data});
      } else {
        yield put( { type: types.EDITED_WORK_UPLOAD_SUCCESSFUL, tempId: workToUpload.key, payload: response.data } );
        yield put( { type: types.SET_EDITED_WORK, payload: response.data } );
      }
      yield put( { type: types.FETCH_PROJECTS } );
    } else {
      if (creating) {
        yield put( { type: types.WORK_UPLOAD_FAILED, tempId: workToUpload.key });
      } else {
        yield put( { type: types.EDITED_WORK_UPLOAD_FAILED, tempId: workToUpload.key });
      }
    }
  } catch (e) {
    let tempId = null;
    if ('key' in workToUpload) {
      tempId = workToUpload.key;
    }
    yield fork(exception, e, tempId);
    console.error(e.response);
    if (creating) {
      yield put( { type: types.WORK_UPLOAD_FAILED, tempId });
    } else {
      yield put( { type: types.EDITED_WORK_UPLOAD_FAILED, tempId });
    }
  }
}

export function* updatePendingCreatedWork(action) {
  try {
    const payload = action.payload;

    yield put( { type: types.SET_PENDING_UPDATE_CREATED, payload: payload });

    yield fork(success, 'Arbeit erfolgreich aktualisiert',
      'Die Arbeit wurde erfolgreich aktualisiert und wird nun hochgeladen oder sobald eine Internetverbindung verfügbar ist. Sie können jetzt die nächste Arbeit eingeben.', 3);

    yield put(push('/arbeiten'));

    setTimeout(() => window.scrollTo(0,0), 100);
  } catch (e) {
    yield fork(exception, e);
  }
}

function uploadingSinceMinutes(uploadStartedAt) {
  const now = new Date();
  const uploadedStarted = new Date(uploadStartedAt);
  var diff =(now.getTime() - uploadedStarted.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function* deleteWork(action) {
  try {
    yield call(api.deleteWork, action.workId);
    yield put( { type: types.WORK_DELETE_SUCCESSFUL, workId: action.workId } );
    yield fork(success, 'Arbeit gelöscht', 'Die Arbeit (#'+action.workId+') wurde erfolgreich gelöscht.', 3);
  } catch (e) {
    console.error(e.response);
    // yield put(fetchFailed(e));
    yield put( { type: types.WORK_DELETE_FAILED });
    return;
  }
}

export function* retryPendingWorks() {
  while(true) {
    // Check if the user is logged in
    let user = yield select( state => state.user.user);
    let pendingWorks = yield select( state => state.pendingWorks.works);
    let pendingEditedWorks = yield select( state => state.pendingWorks.editedWorks)
    if(user && Object.keys(pendingWorks).length > 0) {
      yield call( uploadWork );
    }
    if(user && Object.keys(pendingEditedWorks).length > 0) {
      yield call( uploadWork, 'updating' );
    }

      // Wait for 2 seconds
    yield delay(2000);
  }
}

export function* markAsSolved(action) {
  try {
    yield put( { type: types.SOLVE_WORK_STARTED, workId: action.workId } );

    let data = {
      work_id: action.workId
    };

    // Get the image by ID
    if( action.fileId ) {
      data.image = yield call(read, action.fileId);
    }

    const response = yield call(api.markAsSolved, data);

    yield put( { type: types.SOLVE_WORK_SUCCESSFUL, workId: action.workId, payload: response.data } );
  } catch (e) {
    yield fork(exception, e);
    yield put( { type: types.SOLVE_WORK_FAILED, workId: action.workId });
    console.error(e.response);
    return;
  }
}
