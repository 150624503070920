import { call, put, fork, select } from 'redux-saga/effects'
import * as types from '../actionTypes';
import { setProjects } from '../actions/projects';
import * as api from '../services/api';
import { exception } from './alerts';

export function* fetchProjects() {
  yield put( { type: types.FETCH_PROJECTS_STARTED });

  try {
    const offlinePlanIds = yield select(state => state.projects.offlinePlanIds);
    const response = yield call(api.fetchProjects, offlinePlanIds);

    yield put( setProjects(response.data.data));
    yield put( { type: types.FETCH_PROJECTS_SUCCESSFUL } );
  } catch (e) {
    console.error(e);
    yield fork(exception, e);
    yield put( { type: types.FETCH_PROJECTS_FAILED });
  }
}
