import { call, put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import * as types from '../actionTypes';

export function* offlineDetector() {
  // Init
  yield put({ type: types.ONLINE_STATE_CHANGED, online: navigator.onLine });

  // Wait for events
  const channel = yield call(onlineOfflineChannel)
  while (true) {
    // take(END) will cause the saga to terminate by jumping to the finally block
    let online = yield take(channel);
    yield put({ type: types.ONLINE_STATE_CHANGED, online: online });
  }
}

function onlineOfflineChannel() {
  return eventChannel(emitter => {
    window.addEventListener('online', () => emitter(true) );
    window.addEventListener('offline', () => emitter(false) );
    return () => {};
  });
}
