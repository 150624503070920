import * as types from "../actionTypes";
import {removeObjectField} from "../services/helpers";

const initialState = {
  loading: false,
  all: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_MY_WORKS: {
      const { works, attach } = action;
      if (attach) {
        return {
          ...state,
          all: state.all.concat(works)
        }
      }
      return {
        ...state,
        all: works,
      }
    }
    case types.FETCH_MY_WORKS_STARTED: {
      return {
        ...state,
        loading: true,
      }
    }
    case types.FETCH_MY_WORKS_SUCCESSFUL: {
      return {
        ...state,
        loading: false,
      }
    }
    case types.FETCH_MY_WORKS_FAILED: {
      return {
        ...state,
        loading: false,
      }
    }

    case types.WORK_UPLOAD_SUCCESSFUL: {
      return {
        ...state,
        all: [
          action.payload,
          ...state.all
        ]
      }
    }

    case types.SOLVE_WORK_STARTED: {
      const workIndex = state.all.findIndex(work => work.id === action.workId);
      state.all[workIndex] = {
        ...state.all[workIndex],
        uploading: true
      };
      return {
        ...state,
      }
    }
    case types.SOLVE_WORK_SUCCESSFUL: {
      const workIndex = state.all.findIndex(work => work.id === action.workId);
      state.all[workIndex] = {
        ...action.payload,
        uploading: false
      };
      return {
        ...state,
      }
    }
    case types.SOLVE_WORK_FAILED: {
      const workIndex = state.all.findIndex(work => work.id === action.workId);
      state.all[workIndex] = {
        ...state.all[workIndex],
        uploading: false
      };
      return {
        ...state,
      }
    }

    case types.WORK_DELETE_SUCCESSFUL: {
      return {
        ...state,
        all: state.all.filter( work => work.id !== action.workId ),
      }
    }

    default:
      return state;
  }
}
