import React, { Component } from 'react';
import { Form, Segment, Button, Icon, Container, Input } from 'semantic-ui-react'
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import Alerts from '../Alerts'
import * as actionTypes from '../../actionTypes';
import './styles.css'

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    }

    this.validator = new SimpleReactValidator();
  }

  render() {
    if( this.props.user.user ) {
      this.props.history.push('/');
    }

    return (
      <div className="login-box">
        <Container fluid>
          <Form loading={this.props.user.loggingIn}>
            <Segment>
              <div className="login-logo-box">
                <img alt="Login Logo" className="login-logo" src={ require('../../assets/logo.png')} />
              </div>

              <Alerts />


              <Form.Field required>
                <label>Benutzername</label>
                <Input
                  fluid
                  placeholder={'Ihr Benutzername'}
                  value={this.state.username}
                  onChange={ event => this.setState({ username: event.target.value })}
                />
                {this.validator.message('username', this.state.username, 'required')}
              </Form.Field>

              <Form.Field required>
                <label>Passwort</label>
                <Input
                  type='password'
                  fluid
                  placeholder={'Ihr Passwort'}
                  value={this.state.password}
                  onChange={ event => this.setState({ password: event.target.value })}
                />
                {this.validator.message('password', this.state.password, 'required')}
              </Form.Field>

              <Button style={{marginBottom: 10}} icon fluid primary labelPosition='left' onClick={ this.submit.bind(this) } >
                <Icon name='key' />
                Anmelden
              </Button>
              <Button basic icon fluid as='a' href="mailto:info@allseal.ch" >
                Administrator kontaktieren
              </Button>
            </Segment>
          </Form>
        </Container>
      </div>
    );
  }

  submit() {
    if (this.validator.allValid()) {
      this.props.login(this.state);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    login: credentials => dispatch({ type: actionTypes.LOGIN, credentials }),
  }
}

// export default TodoList;
export default connect(mapStateToProps, mapDispatchToProps)(Login);
