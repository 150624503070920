import { call, put, delay } from 'redux-saga/effects'
import * as types from '../actionTypes';

export function* error(title, message, removeAfterSeconds = null) {
  yield call(alert, title, message, 'error', removeAfterSeconds);
}

export function* exception(e, tempId = null) {

  const ignoredExceptions = [ 'Network Error' ];

  if(ignoredExceptions.indexOf(e.message) < 0) {
    let errors = '';
    if ('response' in e && 'data' in e.response) {
      Object.keys(e.response.data).forEach(key => {
        errors += `Field: ${key}, error: ${e.response.data[key]} \n`
      });
    }

    let message = `${e.stack} ${errors}`;
    if (tempId) {
      message = `${message} (WTID: ${tempId})`
    }
    yield call(alert, `Unewarteter Fehler: ${e.message}`, message, 'error', null);
  }
}

export function* success(title, message, removeAfterSeconds = null) {
  yield call(alert, title, message, 'success', removeAfterSeconds);
}

export function* info(title, message, removeAfterSeconds = null) {
  yield call(alert, title, message, 'info', removeAfterSeconds);
}

export function* errorAlert({payload}) {
  yield call(error, payload.title, payload.message, 15)
}

function* alert(title, message, type, removeAfterSeconds) {

  const id = Math.floor(Date.now() / 1000);

  yield put({
    id: id,
    type: types.ADD_ALERT,
    title: title,
    message: message,
    alertType: type
  })

  if(removeAfterSeconds) {
    // Wait for X seconds
    yield delay(removeAfterSeconds*1000);

    // Remove alert
    yield put({
      type: types.REMOVE_ALERT,
      id: id
    })
  }
}
