import { takeEvery, takeLatest, fork } from 'redux-saga/effects'
import * as types from '../actionTypes';
import { fetchProjects } from './projects';
import { fetchWorks, fetchWork, deleteWork, uploadWork, newWork, updateWork, updatePendingCreatedWork,
  retryPendingWorks, fetchWorkForPlan,
  markAsSolved } from './works';
import { fetchMyWorks } from './myWorks';
import { login, logout } from './user';
import { offlineDetector } from './offlineDetector';
import { cleanLocalStorage } from './cleanLocalStorage';
import { errorAlert } from './alerts'

export function* init() {
  yield fork(offlineDetector);
  yield fork(retryPendingWorks);
  yield fork(cleanLocalStorage);

  yield takeLatest(types.ONLINE_STATE_CHANGED, uploadWork);
  yield takeLatest(types.FETCH_PROJECTS, fetchProjects);
  yield takeLatest(types.FETCH_WORKS, fetchWorks);
  yield takeLatest(types.FETCH_WORK, fetchWork);
  yield takeLatest(types.FETCH_MY_WORKS, fetchMyWorks);
  yield takeLatest(types.FETCH_WORK_FOR_PLAN, fetchWorkForPlan);
  yield takeEvery(types.NEW_WORK, newWork);
  yield takeEvery(types.UPDATE_WORK, updateWork);
  yield takeEvery(types.PENDING_UPDATE_CREATED, updatePendingCreatedWork);

  yield takeEvery(types.UPLOAD_WORK, uploadWork);
  yield takeEvery(types.DELETE_WORK, deleteWork);
  yield takeLatest(types.LOGIN, login);
  yield takeLatest(types.LOGOUT, logout);
  yield takeLatest(types.MARK_AS_SOLVED, markAsSolved);
  yield takeEvery(types.ERROR_ALERT, errorAlert);
}
