import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from "./reducers";
import { createBrowserHistory } from 'history'
import logger from 'redux-logger'
import { init } from './sagas'

export const history = createBrowserHistory()

const DEBUG = process.env.NODE_ENV !== 'production';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [ 'alerts', 'router', 'online', 'works', 'myWorks' ]
};

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const sagaMiddleware = createSagaMiddleware()

const middleware = [
  routerMiddleware(history),
  DEBUG && logger,
  sagaMiddleware
].filter(Boolean);

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
)
sagaMiddleware.run(init)

export const persistor = persistStore(store)

export default { store, persistor };
