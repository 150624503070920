import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Label, Input, Menu, Loader } from 'semantic-ui-react'

export default ({projects}) => {
  const [search, setSearch] = useState('');

  const preLoadImages = plans => {
    if( !projects.offlinePlanIds ) return null;

    return plans
      .filter( plan => projects.offlinePlanIds.includes(plan.id))
      .map( plan => <img key={'prefetch-'+plan.id} alt="Vorgeladenes Bild" className="prefetch-image" src={plan.publicFile} /> );
  }

  return (
    <Menu vertical fluid>
      <Menu.Item>
        <h4>{projects.allIds.length} Projekte</h4>
      </Menu.Item>
      <Menu.Item>
        <Input
          icon='search'
          placeholder='Projekt suchen...'
          value={search}
          onChange={ event => setSearch(event.target.value) }
       />
      </Menu.Item>
      {projects.allIds.map(p => {
        if( search === '' || RegExp(search, 'i').test(projects.byIds[p].name) ) {
          return (
            <Menu.Item key={ 'projects-'+p } as={Link} to={`projekt/${p}`}>
              <Label>{projects.byIds[p].plans.length}</Label>
              {projects.byIds[p].name} <span className="entry-id">#{projects.byIds[p].id}</span>
              {preLoadImages(projects.byIds[p].plans)}
            </Menu.Item>
          );
        } else return null;
      })}
      {projects.loading && projects.allIds.length === 0 &&
        <Menu.Item className="loading-wrapper">
          <Loader active>Projekte werden geladen...</Loader>
        </Menu.Item>
      }
    </Menu>
  );
};
